import ImageViewer from "@/components/image-viewer.vue";
import { getMyExamOcrQuestionOptions, getMyExamOcrReviewQuestionInfo, getMyExamOcrReviewRate, saveMyExamOcrQuestion, myExamOcrResetJugdePaper } from "@/api/api";
export default {
  name: "examOcrReviewInfo",
  components: {
    ImageViewer
  },
  data() {
    return {
      exam_id: null,
      question_options: [],
      current_question_id: null,
      current_exam_result: null,
      current_answer: '',
      count_all_over: 0,
      count_all_total: 0,
      count_all_rate: 0,
      count_current_over: 0,
      count_current_total: 0,
      formData: {
        exam_result_id: '',
        answer: '',
        check_result: ''
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.params;
    this.exam_id = data.exam_id;
    this.getQuestionOptions();
    this.$nextTick(() => {
      // this.$refs.elImageViewer.unregisterEventListener()
    });
  },
  methods: {
    getQuestionOptions() {
      let that = this;
      getMyExamOcrQuestionOptions({
        exam_id: that.exam_id,
        type: 'ocr'
      }).then(res => {
        if (res.data.length < 1) {
          that.$message.error('没有题型数据');
          return;
        }
        that.question_options = res.data;
        that.current_question_id = res.data[0].question_id;
        that.getQuestionInfo();
      });
    },
    getQuestionInfo(click = '') {
      let that = this;
      getMyExamOcrReviewRate({
        exam_id: that.exam_id,
        question_id: that.current_question_id
      }).then(res => {
        var _that$current_exam_re;
        that.count_all_total = res.data.count_all_total;
        that.count_current_total = res.data.count_current_total;
        that.count_all_over = res.data.count_all_over;
        that.count_all_rate = res.data.count_all_rate;
        that.count_current_over = res.data.count_current_over;

        // if (that.count_current_over >= that.count_current_total && click === '') {
        //   that.current_exam_result = null
        //   that.clearMath()
        //   that.$message.success("当前题号已检查完")
        //   return
        // }
        getMyExamOcrReviewQuestionInfo({
          exam_id: that.exam_id,
          question_id: that.current_question_id,
          click: click,
          current_id: ((_that$current_exam_re = that.current_exam_result) === null || _that$current_exam_re === void 0 ? void 0 : _that$current_exam_re.id) || ''
        }).then(res => {
          that.current_exam_result = res.data;
          that.formData = {
            exam_result_id: res.data.id,
            answer: res.data.answer,
            check_result: true
          };
          that.current_answer = res.data.answer;
          that.$nextTick(() => {
            that.renderMath();
          });
        }).catch(() => {});
      });
    },
    renderMath() {
      document.getElementById('exam-ocr-math').innerText = this.current_answer;
      if (window.MathJax) {
        console.log('renderMath');
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.getElementById('exam-ocr-math')]);
      }
    },
    clearMath() {
      this.current_exam_result = null;
      this.formData.answer = null;
      document.getElementById('exam-ocr-math').innerText = '';
    },
    onQuestionIdChange() {
      this.current_exam_result = null;
      this.getQuestionInfo('');
    },
    switchCheckResult(check_result) {
      this.formData.check_result = check_result;
    },
    handleEditSave() {
      let that = this;
      if (!that.current_exam_result) return;
      saveMyExamOcrQuestion(that.formData).then(res => {
        that.$message.success('已提交');
        if (that.formData.check_result) {
          // 下一题
          that.getQuestionInfo('next');
        } else {
          // 刷新
          that.getQuestionInfo('');
        }
      });
    },
    handlePanjuan() {
      let that = this;
      if (!that.current_exam_result || that.current_exam_result.ocr_check_state != 2) return;
      myExamOcrResetJugdePaper({
        exam_result_id: this.current_exam_result.id
      }).then(() => {
        that.$message.success('已提交');
        that.getQuestionInfo('next');
      });
    },
    answerChange() {
      let that = this;
      that.current_answer = that.formData.answer;
      that.renderMath();
    }
  }
};