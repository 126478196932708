import "core-js/modules/es.array.push.js";
import { getMyExamOcrQuestionOptions, getMyExamCheckQuestionInfo, saveCheckExamResult, checkExamRate } from "@/api/api";
import ImageViewer from "@/components/image-viewer.vue";
export default {
  name: "examCheckReviewInfo",
  components: {
    ImageViewer
  },
  data() {
    return {
      Info: null,
      // 当前题目信息
      rateInfo: null,
      // 进度信息
      question_options: [],
      // 判卷题选项
      maxScore: null,
      // 满分
      score: '',
      //当前评分
      scoreArr: [],
      // 键盘输入分数
      // 查询数据
      examData: {
        exam_id: '',
        question_id: '',
        click: '',
        current_id: ''
      },
      //
      is_auto: false,
      // 自动提交
      auto_seconds: 3,
      // 3秒后自动提交
      timer: null,
      //
      is_ai: true,
      // AI批改显示
      //
      res_is_json: false,
      judge_result: null
    };
  },
  mounted() {
    let {
      exam_id
    } = this.$root.useRoute.params;
    if (exam_id > 0) {
      this.examData.exam_id = exam_id;
      this.getQuestions();
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    // 获取所有判卷的题
    getQuestions() {
      let that = this;
      getMyExamOcrQuestionOptions({
        exam_id: that.examData.exam_id,
        type: 'check'
      }).then(res => {
        let data = res.data || [];
        if (data && data.length > 0) {
          that.question_options = [...data];
          if (!that.examData.question_id) {
            // 获取没有判完的题
            let question_id = null;
            for (let item of that.question_options) {
              if (item.rate < 100) {
                question_id = item.question_id;
                break;
              }
            }
            that.examData.question_id = question_id ? question_id : that.question_options[0].question_id;
            that.getInfo();
          }
        } else {
          that.$root.ElMessage({
            type: 'error',
            message: '没有判卷检查的数据'
          });
        }
      });
    },
    // 获取题目的详情
    getInfo() {
      let that = this;
      checkExamRate({
        exam_id: that.examData.exam_id,
        question_id: that.examData.question_id
      }).then(res => {
        that.rateInfo = res.data;
        if (that.rateInfo.count_current_over >= that.rateInfo.count_current_total && that.examData.click === '') {
          that.$message.success("当前题号已检查完");
          return;
        }
        getMyExamCheckQuestionInfo(that.examData).then(res => {
          var _res$data;
          if (!res.data) return;
          that.resetData();
          that.Info = res.data;
          that.score = res.data.score > 0 ? String(res.data.score) : 0; // 当前题的得分
          if (that.score > 0) that.scoreArr = [that.score];
          that.maxScore = ((_res$data = res.data) === null || _res$data === void 0 || (_res$data = _res$data.question) === null || _res$data === void 0 ? void 0 : _res$data.score) || 0; // 满分
          // 自动提交
          if (that.is_auto) {
            that.countDown();
          }
          // ai分析显示问题
          try {
            that.judge_result = JSON.parse(res.data.judge_result);
            console.log('AI分析json', that.judge_result);
            that.res_is_json = true;
            that.judge_result.map(item => {
              that.$nextTick(() => {
                that.renderMath('AiStudent', item['学答步骤']);
                that.renderMath('AiStandard', item['标答步骤']);
                that.renderMath('AiReason', item['判断理由']);
              });
            });
          } catch ({
            name,
            message
          }) {
            console.log('AI分析判断是否是json', name, message);
            that.res_is_json = false;
            that.judge_result = res.data.judge_result;
          }
        });
      });
    },
    renderMath(eleClass, innerText) {
      document.getElementsByClassName(eleClass).innerText = innerText;
      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.getElementsByClassName(eleClass)]);
      }
    },
    // 换题
    handleChangeQuestion() {
      this.examData.click = '';
      this.examData.current_id = '';
      this.Info = null;
      this.getInfo();
    },
    // 上一份 / 下一份
    NextOrPre(key) {
      this.examData.current_id = this.Info ? this.Info.id : '';
      this.examData.click = key;
      this.getInfo();
    },
    resetData() {
      this.score = '';
      this.scoreArr = [];
      this.auto_seconds = 3;
    },
    // 满分、零分
    handleScore(key) {
      if (!this.Info) return;
      if (key === 'manfen') {
        this.score = this.Info.question.score;
      }
      if (key === 'zero') {
        this.score = 0;
      }
    },
    // 输入分数
    handleChooseScore(str) {
      // 删除分数
      if (str === 'del') {
        this.score = '';
        this.scoreArr = [];
        return;
      }
      if (!this.Info) return;
      this.scoreArr.push(str);
      // 舍弃.5h后面的，.5只能在最后
      let indexToRemoveFrom = this.scoreArr.indexOf('.5');
      if (indexToRemoveFrom !== -1) {
        let res = this.scoreArr.splice(indexToRemoveFrom + 1);
        if (res.length > 0) {
          this.$root.ElMessage.error(`分数只能1位小数（0.5）`);
        }
      }
      const temp = this.scoreArr.join('');
      if (Number(temp) > Number(this.maxScore)) {
        this.$root.ElMessage.error(`分数不能超过满分：${this.maxScore}`);
        return;
      }
      this.score = Number(temp);
    },
    handleAiChange(e) {
      if (!this.Info) return;
      this.is_ai = e;
    },
    handleAutoChange(e) {
      this.is_auto = e;
      this.auto_seconds = 3;
      this.countDown();
    },
    //保存提交分数
    saveScore() {
      if (!this.Info) return;
      if (String(this.score).length < 1) {
        this.$root.ElMessage.error(`请输入学生得分`);
        return false;
      }
      if (this.score > this.maxScore) {
        this.$root.ElMessage.error(`分数不能超过满分：${this.maxScore}`);
        return false;
      }
      console.log('判卷---提交数据', {
        id: this.Info.id,
        score: Number(this.score)
      });
      saveCheckExamResult({
        id: this.Info.id,
        score: Number(this.score)
      }).then(() => {
        this.getQuestions();
        this.examData.click = '';
        this.getInfo();
      });
    },
    // 倒计时
    countDown() {
      let that = this;
      if (!that.Info) return;
      if (that.timer) clearInterval(that.timer);
      if (that.is_auto) {
        that.timer = setInterval(() => {
          if (that.auto_seconds < 1) {
            clearInterval(that.timer);
            that.auto_seconds = 3;
            // 请求数据
            console.log('自动提交', String(that.score));
            if (String(that.score) >= 0) {
              that.saveScore();
            } else {
              that.$root.ElMessage.error(`请输入学生得分`);
            }
          } else {
            that.auto_seconds -= 1;
          }
        }, 1000);
      }
    },
    // 全屏
    handleFullScreen() {
      // 获取需要全屏展示的div
      let fullarea = document.getElementById('checkContainer');
      if (!document.fullscreenElement) {
        // 全屏
        fullarea.requestFullscreen();
      } else {
        // 退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  }
};